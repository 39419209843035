import React, { useEffect, useContext } from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import screenShotGif from '../images/img_screenshot1.gif';
import supportImgSVG from '../images/img_featrue_support.svg';
import { ObserverContext } from "../provider/IntersectionObserverProvider";
import { handleObserver } from '../utils/IntersectionObserver'

const FeatureContent = () => {
  const { toTargets, targets } = useContext(ObserverContext);

  useEffect(() => {
    handleObserver(targets)
  }, []);

  return (
    <section className="featureContentContainer">
      <div className="featureItem rev number1 fade-in left" ref={toTargets}>
        <div className="content">
          <p className="number">特徴.<span>1</span></p>
          <h3>どの世代にも愛されるかわいいアバター。<br />
            表情豊かなコミュニケーションが可能に。</h3>
          <p className="text">
            ワカチエの最大の特徴は、アバターを通じて表情豊かなコミュニケーションを可能にすることです。これにより、カメラをオフにした状態でも、参加者は自分の感情や反応を伝えることができます。ワカチエのアバターは個性豊かで愛らしく、ユーザー自身の感情を自然かつダイナミックに表現します。オンライン会議の緊張感を和らげ、より自由でリラックスしたコミュニケーションを促進します。
          </p>
        </div>
        <div className="img">
          <img src={screenShotGif} className="screenShot" />
          <StaticImage src="../images/img_reaction_btn.png" className="reactionBtn" alt={""} />

        </div>
      </div>

      <div className="featureItem number2 fade-in right" ref={toTargets}>
        <div className="content">
          <p className="number">特徴.<span>2</span></p>
          <h3>高い匿名性。<br />
            気兼ねなく意見を言いあえる自由な意見交換。</h3>
          <p className="text">
            ワカチエでは、匿名でのコミュニケーションを実現し、オープンかつ包容的な会話の場を提供します。<br />
            役職や世代の違いによるコミュニケーションの障壁を取り除き、全員が平等に意見を共有できる環境を作り出します。<br />
            真の意味での多様な視点とアイデアが集まり、クリエイティブな議論が促進されます。
          </p>
        </div>
        <div className="img">
          <StaticImage src="../images/img_stamp.png" className="stamp" alt={""} />
          <StaticImage src="../images/img_stamp_btn.png" className="stampBtn" alt={""} />
        </div>
      </div>

      <div className="featureItem rev number3 fade-in left" ref={toTargets}>
        <div className="content">
          <p className="number">特徴.<span>3</span></p>
          <h3>匿名フィードバックによるオープンな対話。</h3>
          <p className="text">
            ワカチエの匿名フィードバック機能は、会議やディスカッション後の意見共有を容易にします。<br />
            参加者は匿名で自分の考えや提案を自由に表現でき、本音を伝えやすくなります。<br />
            率直なフィードバックを促し、チーム内のコミュニケーションの質を高めるために重要な役割を果たします。
          </p>
        </div>
        <div className="img">
          <StaticImage src="../images/img_feature_feedback.png" className="feedback" alt={""} />
        </div>
      </div>

      <div className="featureItem number4 fade-in right" ref={toTargets}>
        <div className="content">
          <p className="number">特徴.<span>4</span></p>
          <h3>ハラスメント通報機能で<br />
            安全な環境を維持。</h3>
          <p className="text">
            安全で健全なコミュニケーション環境の維持に重点を置いたワカチエは、ハラスメント通報機能を備えています。<br />
            参加者は、不適切な行為や言動を匿名で報告でき、これにより全員が安心して会議に参加できます。<br />
            信頼できるワークプレイスの構築に不可欠な機能です。
          </p>
        </div>
        <div className="img">
          <StaticImage src="../images/img_feature_harassment.png" className="harassment" alt={""} />
        </div>
      </div>

      <div className="featureItem rev number5 fade-in left" ref={toTargets}>
        <div className="content">
          <p className="number">特徴.<span>5</span></p>
          <h3>投票機能で迅速な意思決定。</h3>
          <p className="text">
            ワカチエには、直感的な操作で使える簡易多数決機能が含まれています。<br />
            会議中の迅速な意思決定が可能になり、効率的な会議運営をサポートします。<br />
            参加者は瞬時に投票し、即座に結果が集計されます。これにより、重要な決定を素早くかつ民主的に行えます。
          </p>
        </div>
        <div className="img">
          <StaticImage src="../images/img_feature_vote.png" className="vote" alt={""} />
        </div>
      </div>

      <div className="support">
        <h3 className="fade-in up" ref={toTargets}>導入から運用まで、一気通貫でサポート。</h3>
        <div className="supportWrapper fade-in down" ref={toTargets}>
          <div className="img">
            <img src={supportImgSVG} className="supportImg" />
          </div>
          <p className="text">
            導入から運用まで、ワカチエを熟知したエンジニアが御社をサポート。<br />
            IT部署との難しいやりとりも丸投げOK！<br />
            御社のコミュニケーションを次世代のものへアップデートするお手伝いをします。
          </p>
        </div>
      </div>


    </section>
  )
}

export default FeatureContent
