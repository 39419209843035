import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SecondPageHeader from "../container/secondPageHaaderContainer"
import FeatureContent from "../container/featureContentContainer"
import CallToAction from "../container/ctaContainer"

const FeaturePage = () => (
  <Layout>
    <Seo title="機能・特徴 | 顔を出さないで感情の「見える化」をカンタンに。オンライン会議サポートツール | wakachie" />
    <SecondPageHeader englishTitle="- FEATURES -" mainTitle="ワカチエの特徴をご紹介します" isShowTrialLink />
    <FeatureContent />
    <CallToAction />
  </Layout>
)

export default FeaturePage
